<template>
  <div v-if="dataAll" class="productDescription">
    <div class="productDescription_q_icon1"></div>
    <div class="productDescription_q_icon2"></div>
    <div class="productDescription_box1">
      <div class="productDescription_box1_f_r_t a_c">
        <div class="productDescription_box1_f_r_t_l a_flex">
          <img style="width: 82px;height: 46px;" src="https://image.giantgocloud.com/www/ImageMapping/image/20241105/A0EA1915A9A849D5A0FA69CA39F29E22.png" />
        </div>
        <div class="productDescription_box1_f_r_t_text">
            {{dataAll.productName}}
        </div>
      </div>
      <div class="productDescription_box1_body">
        <div class="productDescription_box1_body_img a_flex">
          <img class="wh100" :src="dataAll.productPic" />
        </div>
        <div class="productDescription_box1_body_list">
          <div v-for="(item,index) in dataAll.ingredientList" :key="index" class="productDescription_box1_body_li a_c">
            <div class="productDescription_box1_body_li_icon a_flex">
              <img class="wh100" src="https://image.giantgocloud.com/www/ImageMapping/image/20241105/8DCA867B1B6143778D17FD0A0C7781E1.png" alt="" />
            </div>
            <div class="productDescription_box1_body_li_text">
              {{item.effect}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="productDescription_box2">
      <div class="productDescription_box2_icon">
        <div style="position: relative;width: 100%;height: 100%;">
          <img class="wh100" style="position: absolute;" src="https://image.giantgocloud.com/www/ImageMapping/image/20241105/34BEAE123D214F298957AD825FA87FF6.png" alt="" />
          <div class="productDescription_box2_icon_text">功效</div>
          <div class="productDescription_box2_icon_text">机制</div>
        </div>
      </div>
      <div class="productDescription_box2_ul">
        <div v-for="(item,index) in dataAll.effectList" :key="index" class="productDescription_box2_li">
          <div class="productDescription_box2_li_top">
            <div class="productDescription_box2_li_img">
              <img class="wh100" src="https://image.giantgocloud.com/www/ImageMapping/image/20241105/9BB92AF7D43C4B2D9E1CF566B981181C.png" alt="" />
            </div>
            <div class="productDescription_box2_li_title">
              {{item.ingredient}}
            </div>
          </div>
          <div class="productDescription_box2_li_top_bottom">
            <img style="width: 13px;height: 1px;left: 37px;top:15px;position: absolute;" src="https://image.giantgocloud.com/www/ImageMapping/image/20241105/47EF764D83DF4ABEB82B8DC2F2986997.png" alt="" />
            <div class="productDescription_box2_li_top_bottom_text">
              {{item.effectMechinasm}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="productDescription_box3">
      <img class="wh100" src="https://image.giantgocloud.com/www/ImageMapping/image/20241105/DF9B79FF6AEA43F680C21F17AA4A6BAD.png" alt="" />
      <div class="productDescription_box3_ul">
        <div v-for="(item,index) in dataAll.effectList" :key="index" class="productDescription_box3_li">
          {{item.literature}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import H5Report from '@/api/index'
export default {
  data () {
    return {
      dataAll: ''
    }
  },
  mounted () {
    const that = this
    const ProductCode = this.$query('productCode')
    console.log('ProductCode', ProductCode)
    axios
      .post(
          // `https://healthingcoretest.giantgocloud.com/api/SceneCheckPage/GetPrecisePdfPageData`,
          `${H5Report}/boardApi/CustomerHealingInfo/GetProductDetail`, {
            ProductCode
          }
      ).then(res => {
        console.log('res', res)
        that.dataAll = res.data.data
      })
  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
  .productDescription{
    position: relative;
        box-sizing: border-box;
        width: 1190px !important;
    overflow: hidden;
    position: relative;
    background-color: #f8f8f8;
    padding-bottom: 150px;
    .productDescription_q_icon1{
      position: absolute;
      width: 63px;
      height: 221px;
      background: #FFFFFF;
      box-shadow: 0px 7px 14px 0px #EAEAEA;
      border-radius: 0 56px 0 0;
      left:0;
      bottom:0;
    }
    .productDescription_q_icon2{
      position: absolute;
      width: 630px;
      height: 69px;
      background: #FFFFFF;
      box-shadow: 0px 7px 14px 0px #EAEAEA;
      border-radius: 56px 0 0 0;
      right: 0;
      bottom: 0;
    }

    .productDescription_box1{
      box-sizing: border-box;
      padding-right: 60px;
      position: relative;
      margin-top: 56px;
      margin-left: 58px;
      padding-top: 35px;
      padding-bottom: 52px;
      width: 1080px;
      background: #FFFFFF;
      box-shadow: 0px 7px 14px 0px #EAEAEA;
      border-radius: 56px;
      .productDescription_box1_f_r_t{
        position: absolute;
        justify-content: space-between;
        right: 0;
        top: -34.5px;
        width: 543px;
        height: 69px;
        background: #81B02E;
        border-radius: 34px 34px 0px 34px;
        .productDescription_box1_f_r_t_l{
          width: 134px;
          height: 53px;
          background: linear-gradient(37deg, #FFFFFF, #C1BFBF);
          border-radius: 27px;
          margin-left: 9px;
        }
        .productDescription_box1_f_r_t_text{
          width: 100%;
          text-align: center;
          font-weight: 800;
          font-size: 34px;
          color: #FFFFFF;
          margin-right: 67px;
        }
      }
      .productDescription_box1_body{
        display: flex;
        align-items: center;
        margin-top: 30px;
        .productDescription_box1_body_img{
          margin-left: 64px;
          flex-shrink: 0;
          overflow: hidden;
          border-radius: 50%;
          width: 290px;
          height: 290px;
        }
        .productDescription_box1_body_list{
          margin-left: 49px;
          width:656px;
          .productDescription_box1_body_li{
            margin-bottom: 16px;
            .productDescription_box1_body_li_icon{
              flex-shrink: 0;
              width: 27.5px;
              height:27.5px;
            }
            .productDescription_box1_body_li_text{
              margin-left: 20px;
              font-weight: 500;
              font-size: 28px;
              color: #595757;
            }
          }
          .productDescription_box1_body_li:nth-last-of-type(1){
            margin-bottom: 0;
          }
        }
      }
    }
    .productDescription_box2{
      box-sizing: border-box;
      position: relative;
      margin-left: 58px;
      margin-top: 72px;
      width: 1080px;
      /* height: 571px; */
      padding-right: 50px;
      background: #FFFFFF;
      box-shadow: 0px 7px 14px 0px #EAEAEA;
      border-radius: 56px;
      padding-top: 58px;
      padding-bottom: 65px;
      .productDescription_box2_icon{
        position: absolute;
        right:51px;
        top: -45px;
        width: 93px;
        height: 119px;
        .productDescription_box2_icon_text{
          position: relative;
          font-weight: 800;
          font-size: 28px;
          color: #FFFFFF;
          margin-left: 11px;
          top: 8px;
        }
      }
      .productDescription_box2_ul{
        margin-left: 79px;
        .productDescription_box2_li{
          margin-bottom: 17px;
          .productDescription_box2_li_top{
            display: flex;
            align-items: flex-end;
            .productDescription_box2_li_img{
              width: 33px;
              height: 25px;
            }
            .productDescription_box2_li_title{
              margin-left: 27px;
              font-weight: 600;
              font-size: 28px;
              color: #595757;
            }
          }
          .productDescription_box2_li_top_bottom{
            position: relative;
            margin-top: 10px;
            .productDescription_box2_li_top_bottom_text{
              margin-left: 60px;
              font-weight: 400;
              font-size: 20px;
              color: #595757;
            }
          }
        }
        .productDescription_box2_li:nth-last-of-type(1){
          margin-bottom: 0;
        }
      }
    }
    .productDescription_box3{
      position: relative;
      width: 1012px;
      height: 294.2px;
      margin-top: 50px;
      margin-left: 80px;
      .productDescription_box3_ul{
        position: absolute;
        left:170px;
        top: 70px;
          .productDescription_box3_li{
            font-weight: 400;
            font-size: 17px;
            color: #595757;
            line-height: 22px;
          }
      }
    }
  }

</style>
